import './App.css';
import data from './content.json'
import { Navbar } from './Navbar';
import { Route, Routes} from 'react-router-dom'
import { Home } from './Home';
import { About } from './About';
import { FAQs } from './FAQs';
import { Contact } from './Contact';
import { Training } from './Training';
import {NewsPage} from './NewsPage'
import { Article } from './Article';
import { PhotoReel } from './PhotoReel';
import { Committee } from './Committee';

function App() {

  console.log(data)

  return (
    <div className="App">
      <header className="App-header">
        <div className='name-logo'>
          <h1>Dundee Korfball Club</h1>
          <img src="DKC-removebg-preview.png" alt='Dundee Korfball Club Logo' height='100px' width='100px' />
        </div>
        <div className='socials'>
          <a href='https://www.facebook.com/dundeekorf/'><i className="bi bi-facebook"></i></a>
          <a href='https://twitter.com/dundeekorfball'><i className="bi bi-twitter"></i></a>
          <a href='https://instagram.com/dundeekorfball'><i className="bi bi-instagram"></i></a> 
        </div>
        
      </header>
      
        
          <Navbar/>
          <div className='App-body'>
            <Routes>
              <Route exact path="" element={<Home/>}/>
              <Route path="/FAQs" element={<FAQs/>}/>
              <Route path="/contact" element={<Contact/>}/>
              <Route path="/about" element={<About/>} />
              <Route path="/training" element={<Training/>}/>
              <Route path="/news" element={<NewsPage/>}/>
              <Route path="/Gallery" element={<PhotoReel/>}/>
              <Route path="/Committee" element={<Committee/>}/>
              <Route path="/news/:id" element={<Article/>}/>
            </Routes>
            
            
          </div>
        
    </div>

  );
}

export default App;
