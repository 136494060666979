import { useState, useEffect } from "react";

export const PhotoReel = () => {
  const [images, setImages] = useState([])
  const [currentImage, setCurrentImage] = useState(0);

  const query = `query {
    assetCollection (where: {
      contentfulMetadata:{
        tags:{
          id_contains_all: "galleryPic"
        }
      }
    }) {
      items {
        fileName,
        url
      }
    }
      
  }`

  useEffect(() => {
    window
      .fetch(process.env.REACT_APP_CONTENTFUL_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: process.env.REACT_APP_CONTENTFUL_API_KEY,
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setImages(data.assetCollection.items);
      });
  }, [query]);

  const updateImage = () => {
    if (currentImage >= images.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage+1)
    }
  }

  if (images.length === 0) {
    return <p>loading...</p>
  } else {
    console.log(images)
  }

  return <>
  <h2> Selection of photos of our lovely korfers</h2>
  <div className="gallery">
    <button onClick={updateImage}><i className="bi bi-arrow-left"></i></button>
    <img className="reel" alt={"DKC"} src={images[currentImage].url}/>
    <button onClick={updateImage}><i className="bi bi-arrow-right"></i></button>
  </div>
  </>
}