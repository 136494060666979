import { useState } from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"

export const Navbar = () => {
  let location = useLocation()
  const [active, setActive] = useState(location.pathname)
  
  const handleClick = (event, name) => {
    setActive(name)
  }


  return (
    <div className='navbar'>
      <Link to={'/'} className={active === "/" ? "active" : "not"} onClick={(e) => handleClick(e, "/")} >Home</Link>
      <Link to={'/news'} className={active === "/news" ? "active" : "not"} onClick={(e) => handleClick(e, "/news")} >News</Link>
      <Link to={'/contact'} className={active === "/contact" ? "active" : "not"} onClick={(e) => handleClick(e, "/contact")} >Contact</Link>
      <Link to={'/about'} className={active === "/about" ? "active" : "not"} onClick={(e) => handleClick(e, "/about")} >About</Link>
      <Link to={'/training'} className={active === "/training" ? "active" : "not"} onClick={(e) => handleClick(e, "/training")} >Training</Link>
      <Link to={'/FAQs'} className={active === "/FAQs" ? "active" : "not"} onClick={(e) => handleClick(e, "/FAQs")} >FAQs</Link>
      <Link to={'/Gallery'} className={active === "/Gallery" ? "active" : "not"} onClick={(e) => handleClick(e, "/Gallery")} >Gallery</Link>
      <Link to={'/Committee'} className={active === "/Committee" ? "active" : "not"} onClick={(e) => handleClick(e, "/Committee")} >Committee</Link>
    </div>
  )
}