
export const Training = () => {
  return (
    <div>
      <p>
        TUESDAYS: 7:30-9pm @ Ward Road Gym <br />
        <iframe className="training" title="wrg" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2532.8338062627067!2d-2.9767969574371533!3d56.46177413015979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48865cc053139059%3A0xa2fe77a1badc639b!2sWard%20Road%20Gym!5e0!3m2!1sen!2suk!4v1723282859891!5m2!1sen!2suk" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </p>
      <p>
      THURSDAYS: 6:30-8pm @ Ardler community centre <br/>
      <iframe className="training" title="ardler" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.548117583392!2d-3.012382564596426!3d56.489529126818276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x488642d16bc0c8d1%3A0xd25a59478c5a6b11!2sArdler%20Complex!5e0!3m2!1sen!2suk!4v1723282793122!5m2!1sen!2suk" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </p>
    </div>
  )
}