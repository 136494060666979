import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Page } from "./Page";

export const Article = () => {
  const [article, setArticle] = useState(null);
  const id = useParams().id;
  console.log(id)

  const query = `query {
    pageBlogPost(id: "${id}") {
      title,
      content{
        json
      },
      author {
        name
      }
    }
  }
  `

  useEffect(() => {
    window
      .fetch(process.env.REACT_APP_CONTENTFUL_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: process.env.REACT_APP_CONTENTFUL_API_KEY,
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setArticle(data.pageBlogPost);
      });
  }, [query]);

  if (!article) {
    return <p>Loading...</p>
  }

  console.log(article)

  return (
    <Page title={article.title} author={article.author.name} article={article.content.json}/>
  )

}