import { useState } from "react";

export const Contact = () => {
  const [formData, setFormData] = useState({name: "", email: "", message: ""})

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const isInvalidFormData = () => {
    if (formData.message === "" || formData.name === "") {
      return true
    }

    return false
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (isInvalidFormData()) {
      alert("Invalid form submission, please try again")
      return
    }
    
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData })
    })
      .then(() => {
        alert("Success! We have received your message and will respond in due course :)")
        setFormData({name: "", email: "", message: ""})
    })
      .catch(error => {
        alert("A problem occurred, please try again later :(")
    });

    

  }
  return (
    <>
    <h3>Contact us</h3>
    <p>Want to find out more about Dundee Korfball Club?</p>
    <p>
      By phone: <br/>
      Nigel Cooper,  07817 640432
    </p>
    <p>
      By email: <br/>
      <a href="mailto:korfballdundee@gmail.com">KorfballDundee@gmail.com</a>
    </p>
    <p>
      Or fill out the form below:
    </p>
    <form name="contact" method="POST" data-netlify="true" onSubmit={onSubmit}>
    <p>
      <label>Your Name: <input type="text" name="name" value={formData.name} onChange={(e) => setFormData({...formData, "name": e.target.value})} /></label>
    </p>
    <p>
      <label>Your Email: <input type="email" name="email" value={formData.email} onChange={(e) => setFormData({...formData, "email": e.target.value})} /></label>
    </p>
    <p>
      <label>Message: </label><textarea name="message" value={formData.message} onChange={(e) => setFormData({...formData, "message": e.target.value})}></textarea>
    </p>
      <input type="hidden" name="contact" value="contact"/>
    <p>
      <button type="submit">Send</button>
    </p>
    </form>
    </>
    
  )
}