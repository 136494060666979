import { Image } from "./Image"

const ParagraphContent = ({content}) => {
  return content.map( (content) => {
  if (content.nodeType === "text") {
    return <>{content.value}</>
  } else if (content.nodeType === "hyperlink") {
    return <a href={content.data.uri}><ParagraphContent content={content.content}/></a>
  } else {
    return <>unsupported format</>
  }
  })
}
export const Page = ({title, author, article, id}) => {

  let paras = []
  article.content.map(function (para) {
    if (para.nodeType === "paragraph") {
      paras.push(para.content[0].value)
    }
    return null
  })
  // article.content.map((para) => paras.push(para.content[0].value))

  // console.log(paras)
  

  return (
    <>
    <h3>{id ? <a href={'/news/'+id}>{title}</a> : <>{title}</>}</h3>
    <small>Author: {author}</small>
    {article.content.map((para) => {
      if (para.nodeType === "paragraph") {
        let paragraph = <p></p>
        return <p><ParagraphContent content={para.content} markup={paragraph}/></p>
      } else if (para.nodeType === "embedded-entry-block") {
        return <Image id={para.data.target.sys.id}/>
      } else {
        return <p>unsupported data :/</p>
      }
        
    })}
    </>
  )

}