import { Content } from "./Content"
import content from "./content.json"
import {InstaEmbed} from './InstaEmbed'

export const Home = (props) => {
  return (
    <>
    <Content content={content.home} />
    <InstaEmbed />
    </>
    
  )
}