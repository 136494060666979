import { useEffect, useState } from "react"

export const Image = ({id}) => {
  const [data, setData] = useState(null)
  let query =  `
  query {
    componentRichImage(id: "${id}") {
      image {
        url,
        title,
        height,
        width
      }
    }
  }`

  useEffect(() => {
    window
      .fetch(process.env.REACT_APP_CONTENTFUL_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: process.env.REACT_APP_CONTENTFUL_API_KEY,
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setData(data.componentRichImage.image);
      });
  }, [query]);

  if (data === null) {
    return <p>loading image...</p>
  }

  return <img src={data.url} alt={data.title} width={data.width} height={data.height} />
}
