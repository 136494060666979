export const FAQs = (props) => {
  return (
    <>
    <h3>What even is Korfball?</h3>
    <p>
      Korfball is a ball sport played by mixed teams (four male and four female players per team). It's played with a ball about the size of a football, and involves lots of throwing, catching, and shooting into a really high basket. If you imagine crossing basketball and netball you'll get a fair idea of what korfball looks like, but it’s actually quite distinct from both. It's fast moving, skillful, non-contact and very much dependent on teamwork.
      For more info see <a href="/about">this link</a>
    </p>

    <h3>Where even is Korfball?</h3>
    <p>
      We currently train at Ward Road Gym (Tuesday) and Ardler Community Centre (Thursday). <br/>
      For more info see <a href="/training">this link</a>
    </p>

    <h3>What does training look like?</h3>
    <p>
    A typical training session might be split into three parts: The first few minutes is spent setting up the posts and introducing the basic rules and techniques to any new people that show up (other people generally get in a bit of shooting practice at this point). 
    The next 30-45 minutes includes a short warm-up (fitness and ball handling) and then some drills, focusing on skills and tactics training. 
    The final 30-45 minutes is usually spent playing a proper game of korfball.

    Everyone is free to join in at their own pace, and nobody minds if someone wants to sit out of a particular exercise. 
    The coaches will ensure there are a few short drink breaks in each session, so it's a good idea to bring a water bottle. <br/>

    Want to know more? <a href="/contact">Get in touch!</a>
    </p>
    <h3>What do I need to bring?</h3>
    <p>
    You don't need any special equipment to play korfball - just a regular pair of trainers and sports clothes. 
    It's a good idea to bring along a bottle of water to drink during the breaks too.

    Want to know more? <a href="/contact">Get in touch!</a>
    </p>
    <h3>How much does it cost?</h3>
    <p>
    We want as many people as possible to come along and try korfball out, so we are happy to offer everyone their first training sessions for free.

    Once we get you hooked (hopefully about half way through your first session), subsequent pay-as-you-go training sessions will cost £4 for adults, and £2 for kids. Concessions (under-18’s, students, people on a low income, etc.) are available to those who need them. Pay-as-you-go match fees (for league games, etc.) are charged at £5 per fixture. 

    People who train and play more regularly are asked to pay their fees monthly by standing order (for the club's banking details see “how to pay”), with full fees costing £20 a month for adults (£12 concessions). The monthly fees cover league matches as well as weekly training sessions.

    Club Membership Fees: are payable by all adult (16+) members, and cost £12 a year. Only fully paid up club members are entitled to vote at the AGM, and to play in official matches for the club.

    </p>

    <h3>What do we use the money for?</h3>
    <p>
    Membership fees cover outgoings like the insurance and registration fees that we pay to the Scottish Korfball Association, while training fees cover things like hall hire, administration and equipment costs.

    Want to know more? <a href="/contact">Get in touch!</a>
    </p>

    <h3>How to pay?</h3>
    <p>
    All payments to Dundee Korfball Club can be made either in cash, by cheque, or by electronic transfer the club's account at the Royal Bank of Scotland.
    The account details are as follows: <br/><br/>
    sort code: <strong>83-50-00</strong><br/>
    account number: <strong>10657158</strong><br/><br/>
    reference (examples):<br/>
    SUBS-your name (e.g. SUBS-MARYC) for monthly payments<br/>
    or SHIRT-your name (e.g. SHIRT-ALANM) for shirts<br/>
    or FEES-your name (e.g. FEES-PAULG) for one-offs like annual membership, tournaments, etc. 
    </p>





    </>
  )
}