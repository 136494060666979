import {InstagramEmbed} from 'react-social-media-embed'

export const InstaEmbed = () => {
  return (
    <div className="insta">
      <InstagramEmbed url="https://www.instagram.com/p/CfJbhKDKRuY/?igsh=MzRlODBiNWFlZA==/"  />
      <InstagramEmbed url="https://www.instagram.com/p/C61wPR5ruG6/?igsh=MzRlODBiNWFlZA=="  />
      <InstagramEmbed url="https://www.instagram.com/p/C9XbQVmt709/?igsh=MzRlODBiNWFlZA=="  />
    </div>
  )
}
