import {InstagramEmbed} from 'react-social-media-embed'

export const Committee = () => {
  return (
    <>
    <h2>Meet our fantastically committed korf committee</h2>
    <div className="insta">
      <InstagramEmbed url="https://www.instagram.com/p/C-aQ48WNDlF/"  />
      <InstagramEmbed url="https://www.instagram.com/p/C-cpPO7NNSF/"  />
      <InstagramEmbed url="https://www.instagram.com/p/C-fN9VoNfT8/"  />
      <InstagramEmbed url="https://www.instagram.com/p/C-hyvTYtH3Z/"  />
      <InstagramEmbed url="https://www.instagram.com/p/C-kXlPwNmec/"  />
      <InstagramEmbed url="https://www.instagram.com/p/C-m8Zl1t1rL/"  />
      <InstagramEmbed url="https://www.instagram.com/p/C-phKeWNnKs/"  />
    </div>
    </>
  )
}