import { useEffect, useState } from "react"
import {Page} from './Page'

export const NewsPage = () => {
  const [pages, setPages] = useState(null);
  const [numOfArticles, setNumOfArticles] = useState(3);

  const query = `query {
    pageBlogPostCollection(limit: ${numOfArticles} order:publishedDate_DESC) {
      items {
        title,
        sys {
          id
        },
        content{
          json
        },
        author {
          name
        }
      }
    }
  }
  `

  useEffect(() => {
    window
      .fetch(process.env.REACT_APP_CONTENTFUL_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: process.env.REACT_APP_CONTENTFUL_API_KEY,
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setPages(data);
      });
  }, [query]);

  if (!pages) {
    return <p>Loading...</p>
  }

  return (
    <>
    <h2>Latest News from DKC</h2>
    {pages.pageBlogPostCollection.items.map((page) => <div className="news"><Page title={page.title} author={page.author.name} article={page.content.json} id={page.sys.id}/> </div>)}
    <button className="loadMoreBtn" onClick={()=> setNumOfArticles(numOfArticles+3)}>Load more articles...</button>
    </>
  )

}