export const About = (props) => {
  return (
    <>
    <h3>What even is Korfball?</h3>
    <p>
      Korfball is a ball sport played by mixed teams (four male and four female players per team). It's played with a ball about the size of a football, and involves lots of throwing, catching, and shooting into a really high basket. If you imagine crossing basketball and netball you'll get a fair idea of what korfball looks like, but it’s actually quite distinct from both. It's fast moving, skillful, non-contact and very much dependent on teamwork.
    </p>
    Trying to sum up the game in a four point nutshell:


    <ul className="list">
      <li>2 boys/men and 2 girls/women play for each team in each half of the pitch, and each marks an opposing player of the same sex.</li>
      <li>Attack in one half of the pitch - defend in the other. Switch roles every 2 goals.</li>
      <li>Shoot from anywhere (in your attacking half) into a basket on top of a 3.5m high post.</li>
      <li>No running with the ball - so lots of “passing and moving”.</li>
    </ul>
    <p>
          Here are some high-level korfball matches to have a look at:
    <p>
      <strong>English league final:</strong><br/>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/c93F4Ub9OWY?si=4o6GJ_kQl2N9gAoF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </p>
    <p>
      <strong>Dutch league final:</strong> <br/>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/DVpQg2NfhNY?si=njafNpsqQkkvkZx8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </p>
    <p>
      <strong>World Cup final:</strong> <br/>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/etA6NXqp_nA?si=Hp30mYy1aBQ0Oxer" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </p>
    </p>
    </>
  )
}